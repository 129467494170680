<!--  -->
<template>
<!-- v-if="active" -->
<!-- :style="{
        'padding-left': `${optLeft}px`,
        'height': appStyle.height
    }" -->
    <div class='ex-com-result-mask' v-if="active" 
    
    > <!--:class="{'bg-disappear': disappear}"-->
        <div class="ex-com-result-mask-mod flex-center" :class="{'bg-disappear': !animationOpen}">
            <div class="com-result-body">
                <div class="award-box" v-if="awardCount">
                    <award :number="awardCount"></award>
                </div>
                <div class="re-item icon" :class="{'bounceIn': animationOpen, 'img-disappear': !animationOpen}"> <!--bounceIn--> <!--:class="{'img-disappear': disappear}"-->
                    <slot name="icon"></slot>
                    <div class="icon-box" :class="resultCul" v-if="resultCul && !$slots['icon']"></div>
                </div>
                <div class="re-item extend" :class="{'disappear': !animationOpen}"> <!--:class="{'disappear': disappear}"-->
                    <slot name="extend"></slot>
                    <div class="extend-box" v-if="resultCul && !$slots['extend']">
                        <div class="count-down-box text-center" :class="{'fadeInUp': animationOpen}"> <!--fadeInUp-->
                            <van-count-down :time="time" format="ss" :auto-start="false" ref="countDown" @finish="finish" @change="change">
                                <template v-slot:default="data">
                                    <span class="text-small text-color">{{data.seconds}}S后自动跳转</span>
                                </template>
                            </van-count-down>
                        </div>
                        <div class="extend-fun" :class="{'fadeIn': animationOpen}"> <!--fadeIn-->
                            <slot name="extend-fun"></slot>
                            <!-- <dia-button type="confirm" shape="square" class="flex-center" @click="next" v-if="!$slots['extend-fun']">
                                <span class="text-normal-a">{{TEXT.components.t15}}</span>
                            </dia-button> -->
                            <confirmPlay  @click="next" v-if="!$slots['extend-fun']" class="next-default-btn">
                                <span class="text-normal-a">{{TEXT.components.t15}}</span>
                            </confirmPlay>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
</template>

<script>
    export default {
        name: '',
        props: {
            result: String,
            awardCount: [Number, String],
            time: {
                type: Number,
                default: 3 * 1000,
				
            }
        },
        data() {
            return {
                active: false,
                resultVal: null,
                disappear:false,
                animationClass: null,
                animationOpen: false,
                optLeft: 0,
                appStyle: {}
            };
        },
        methods: {
            startCountDown(){
                if(!this.$refs['countDown']) return
                this.$refs['countDown'].start()
            },
            resetCountDown(){
                if(!this.$refs['countDown']) return
                this.$refs['countDown'].reset()
            },
            pauseCountDown(){
                if(!this.$refs['countDown']) return
                this.$refs['countDown'].pause()
            },
            paramsInit(opt){
                this.resultVal = null
                let {result} = opt || {}
                if(result) this.resultVal = result
            },
            async open(opt){
                this.sizeChange()
                this.paramsInit(opt)
                this.active = true
                await this._common.nextTick.call(this)
                this.animationOpen = true
                this.resetCountDown()
                this.voicePlay()
                await this._common.settimeout(1000)
                this.startCountDown()
            },
            async close(){
                this.pauseCountDown()
                this.animationOpen = false
                if(this.active) await this._common.settimeout(500)
                
                // await this._common.nextTick.call(this)
                this.active = false
            },
            finish(){
                this.$emit('finish')
            },
            change(){
                this.$emit('change')
            },
            next(){
                // this.animationOpen = !this.animationOpen
				//加了一个消失的效果
				this.disappear = true
                this.$emit('next')
            },
            sizeChange(){
                let optEle = document.querySelector('.com-exercise .exercise-body .options')
                let {left, top} = this._common.getVertexPosition(optEle)
                this.optLeft = left - 1
                let {appStyle = {}} = this.$store.state
                this.appStyle = appStyle
            },
            eventSet(){
                this.eventOff()
                this._common.eventSet('onresize', e => {
                    this.sizeChange()
                })
            },
            eventOff(){
                this._common.eventRemove('onresize')
            },
            async voicePlay(){
                // let type = this.resultCul
                // await this._common.settimeout(150)
                // this._voiceHand.exercisePlay(type)
            }
        },
        created() {
            this.sizeChange()
            this.eventSet()
        },
        components: {
        },
        computed: {
            // mskStyle(){
            //     let {
            //         app: {appStyle = {}} = {}
            //     } = this.$store.state


            // },
            resultCul(){
                if(this.resultVal) return this.resultVal
                return this.result
            },
        },
        watch: {
            active: {
                handler(val){
                    if(!val) this._voiceHand.pauseAll()
                },
                deep: true,
                immediate: true
            }
        },
        mounted() {
            this.sizeChange()
        },
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {
            this.eventOff()
            this.pauseCountDown()
        }, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>
<style lang='less' >
    .ex-com-result-mask{
        position: absolute;
        top: 3px;
        left: -1px;
        width: 267px;
        height: 100%;
        .background-img-max(url('@{assetsUrlV2_0}/exercise/c15.png'));
        .ex-com-result-mask-mod{
            // position: absolute;
            // // right: 0px;
            // top: -@app-padding-head;
            // left: calc(@board-size + @board-options-margin);

            // // width: calc(100% -  @board-size - @board-options-margin);
            // // height: calc(100% + @app-padding-head * 2);
            // width: 272px;
            // height: 375px;
            // background: rgba(0, 0, 0, 0.65);
            width: 100%;
            height: 100%;
        }
        
        .com-result-body{
            position: relative;
            width: 100%;
            .award-box{
                width: 100%;
                position: absolute;
                top: -21px;
                left: 0px;
                .com-global-award{
                    margin: 0px auto;
                }
            }
            .icon-box{
                // width: 100%;
                width: 272px;
                height: 100px;
                margin: 0px auto;
            }
            .icon-box.right{
                background: url('@{assetsUrl}/img/subject/s3.png') no-repeat;
                background-size: 100% 100%;
            }
            .icon-box.wrong{
                background: url('@{assetsUrl}/img/subject/s4.png') no-repeat;
                background-size: 100% 100%;
            }
            .re-item.extend{
                margin-top: 8px;
            }
            .extend-fun{
                margin-top: 4px;
                .com-dia-button{
                    margin: 0px auto;
                }
                .next-default-btn{
                    width: 131px;
                    height: 39px;
                    color: #fff;
                    .btn-text{
                         span[class^='text']{
                            font-size: @normal-ab-font-size;
                            height: @normal-ab-font-size;
                        }
                    }
                   
                    .btn-shadow{
                        display: none;
                    }
                }
            }
        }
    }
	@keyframes bounceIn {
		from,
		50%,
		80%,
		to {
			// animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
		}
	
		0% {
			opacity: 0;
			transform: scale3d(0.3, 0.3, 0.3);
		}
	
		50% {
			transform: scale3d(1.2, 1.2, 1.2);
		}
	
		// 80% {
		// 	transform: scale3d(0.95, 0.95, 0.95);
		// }
	
		to {
			opacity: 1;
			transform: scale3d(1, 1, 1);
		}
	}
	
	.bounceIn {
		animation-duration: 0.5s;
        animation-name: bounceIn;
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        // animation-timing-function: ease-in-out;
	}
	@keyframes fadeInUp {
		from {
			opacity: 0;
			transform: translate3d(0, 100%, 0);
		}
		50% {
			opacity: 0;
			transform: translate3d(0, 100%, 0);
		}
	
		to {
			opacity: 1;
			transform: translate3d(0, 0, 0);
		}
	}
	
	.fadeInUp {
		animation-duration: 0.6s;
		animation-name: fadeInUp;
	}
	@keyframes fadeIn {
		from {
			opacity: 0;
		}
		50% {
			opacity: 0;
		}
		to {
			opacity: 1;
		}
	}
	
	.fadeIn {
		animation-duration: 0.8s;
		animation-name: fadeIn;
	}
	.disappear{
		animation-duration: 0.8s;
		animation-name: fadeOut;
	}
	@keyframes fadeOut {
		0% {
			opacity: 1;
        }
        50% {
			opacity: 1;
        }
		100% {
			opacity: 0;
		}
		
	}
	.bg-disappear{
		animation-duration: 0.6s;
		animation-name: bg-disappear;
	}
	@keyframes bg-disappear {
		// 0% {
		// 	background: rgba(0, 0, 0, 0.65);
        // }
        // 50% {
		// 	background: rgba(0, 0, 0, 0.65);
        // }
		// 100% {
		// 	background: rgba(0, 0, 0, 0);
		// }
		
	}
	.img-disappear{
		animation-duration: 0.6s;
		animation-name: img-disappear;
	}
	@keyframes img-disappear {
		0% {
			opacity: 1;
			transform: scale3d(1, 1, 1);
        }
        50% {
            opacity: 0;
			transform: scale3d(1.1, 1.1, 1.1);
        }
		100% {
			opacity: 0;
			transform: scale3d(1.1, 1.1, 1.1);
		}
		
	}
</style>
<!--  -->
<template>
    <div class='com-exercise'>
        <component-bcg url="exercise/bcg.png"></component-bcg>
        <div class="ex-bcg-box">
            <div class="stone-item black"></div>
            <div class="stone-item white"></div>
        </div>
        <div class="exercise-body">
            <div class="ex-shadow"></div>
            <div class="ex-item board game-board-box">
                <div class="board-body" :style="boardStyle">
                    <board ref="board" :boardSize.sync="boardSize" @emitEvent="emitEvent" :minNum.sync="boardMinNum" :disabledMoveConfirm="disabledMoveConfirm"></board>
                    <div class="board-extend" v-if="$slots['board-extend']">
                        <slot name="board-extend"></slot>
                    </div>
                </div>
            </div>
            <div class="ex-item options game-options-box">
                <div class="options-title">
                    <span class="text-normal-ab bold">{{TEXT.exercise.title}}</span>
                </div>
                <div class="options-body light-dark-color">
                    <div class="title">
                        <div class="title-icon"></div>
                        <div class="title-text bold">
                            <span class="text-normal max-width" v-if="!$slots['title-text']">{{titleText}}</span>
                            <slot name="title-text"></slot>
                        </div>
                    </div>
                    <div class="opt-content bold" :class="{'par-content': $slots['fun-area'] && isSelectEx}">
                        <p class="text-small max-height mini-scrollbar">{{questionTitle}}</p>
                    </div>
                    <div class="opt-foot" v-if="!$slots['fun-area']">
                        <div class="foot-select-box sele" v-if="isSelectEx">
                            <!-- :class="{'right ca-select': gameOverCul && v.item == currentAnswer, 'wrong ca-select': userAnswer == v.item && currentAnswer != v.item}" -->
                            <div class="foot-item" v-for="(v, i) in questionSelect" :key="i"
                            :class="[userAnswer == v.item ? userAnswer == currentAnswer ? 'right' : 'wrong' : '', {'ca-select': userAnswer == v.item}]"
                            >
                                <dia-button type="default-bcg" class="flex-center bold" @click="selectByBtn(v)">
                                    <span class="text-normal-a split5">{{v.item}}</span>
                                    <span class="text-normal-a">{{v.val}}</span>
                                </dia-button>
                            </div>
                        </div>
                        <div class="foot-select-box play" v-if="isPlayEx || isLibertyEx">
                            <confirmPlay :disabled="confirmDisabled" @click="confirmPlay">
                                <span class="text-normal-a">{{confirmText}}</span>
                            </confirmPlay>
                        </div>
                    </div>
                    <div class="opt-foot slot-box" v-if="$slots['fun-area']">
                        <div class="foot-select-box sele par-btn" v-if="isSelectEx">
                            <div class="foot-item" v-for="(v, i) in selectParseList" :key="i"
                            :class="{'right ca-select': gameOverCul && v.item == currentAnswer, 'wrong ca-select': userAnswer == v.item && currentAnswer != v.item}"
                            >
                                <dia-button type="default-bcg" class="flex-center">
                                    <span class="text-normal-a">{{v.item + v.val}}</span>
                                </dia-button>
                            </div>
                        </div>
                        <slot name="fun-area"></slot>
                    </div>
                </div>
                <resultMask :result="gameResult ? 'right' : 'wrong'" ref="resultMask" @finish="countDownFinish" @next="nextEx" :awardCount.sync="awardCount">
                    <div slot="extend-fun" v-if="$slots['extend-fun']">
                        <slot name="extend-fun"></slot>
                    </div>
                    <div slot="extend" v-if="$slots['mask-extend']">
                        <slot name="mask-extend"></slot>
                    </div>
                </resultMask>
            </div>
        </div>
        <!-- <resultMask :result="gameResult ? 'right' : 'wrong'" ref="resultMask" @finish="countDownFinish" @next="nextEx">
            <div slot="extend-fun" v-if="$slots['extend-fun']">
                <slot name="extend-fun"></slot>
            </div>
            <div slot="extend" v-if="$slots['mask-extend']">
                <slot name="mask-extend"></slot>
            </div>
        </resultMask> -->
    </div>
</template>

<script>
    import board from '../board.vue'
    import resultMask from './resultMask.vue'

    export default {
        name: '',
        props: {
            sgf: String,
            awardCount: [Number, String]
        },
        data() {
            return {
                // sgf: '(;CA[gb2312]AB[qd][re][qb][sa][sb]AW[rb][ra][pc][qc][rd]C[LT{}-TYPE{0}-Q{白先，请提掉黑棋数子}-EL{QA，F}-]AP[MultiGo:4.4.4]SZ[19]AB[rc]MULTIGOGM[1](;W[sc]C[T])(;W[qa];B[pa]C[F]))',
                // sgf: '(;CA[gb2312]AB[qg][qh][pd][pe][qb][pb][pc][sb][rc][sd][rh][qa]AW[rf][rg][ra][rb][rd][qd][qe][qc]C[LT{}-TYPE{0}-Q{白先，请提劫}-EL{RE,F}-]AP[MultiGo:4.4.4]SZ[19]AB[qf]MULTIGOGM[1](;W[sc]C[T])(;W[re];B[sa]C[F]))',
                _board: null,
                boardState: false,
                sgfBranch: {},
                nodeKey: this.PARAMS.sgfNodeKey,
                sgfPlayData: null,
                sgfData: null,
                sgfDataCopy: null,
                lastStone: null,
                now: 1,
                userColor: null,
                userAnswer: null,
                exercisesVideo: null,
                userPlayData: [],
                playedSgfData: [],
                boardStyle: {},
                hintResultData: {
                    active: false
                },
                selectAnimation: null,
                userScore: null,
                sgfNow: null,
                gamePlayCoor: {},
                AIplayDelayTime: this.PARAMS.subjectAIplayDelayTime,
                position: 0,
                step:0,
                pageLoading: false,
                answerParseData: {},
                libertyCoor: {},
                libertyConfirm: false,
                nextExAction: false,
                removeStone: {},
                lastRemove: {},
            };
        },
        methods: {
            async dataInit(){
                this.$emit('update', {
                    gameResult: null
                })
                await this.resultMaskOff()
                this.userColor = null
                this.sgfData = null
                this.sgfDataCopy = null
                this.userScore = null
                this.userAnswer = null
                this.sgfNow = null
                this.lastStone = null
                this.nextExAction = false
                this.sgfBranch = {}
                this.userPlayData = []
                this.playedSgfData = []
                // this.libertyCoor = {}
                this.clearAllLiberty()
                this.libertyConfirm = false
                this.hintResultOff()
                
            },
            clearBoard(){
                if(!this._board) return
                this._board.clearBoard()
            },
            emitEvent(opt){
                this._common.emitEvent.call(this, opt)
            },
            update(opt){
                this._common.update.call(this, opt)
            },
            boardLoaded(){
                this._board = this.$refs.board
                // this._board.board.board.addEventListener("click",this.playByclick);
                this._board.board.board.addEventListener('mouseleave', this.removeOutline)
                // this._board.board.board.addEventListener("mousemove",this.stoneOver);
                this.boardState = true
            },
            playByclickEmit(opt){
                let {x, y} = opt || {}
                delete opt.x
                delete opt.y
                this.playByclick(x, y, {...opt})
            },
            async playByclick(x, y, opt){
                if(this.isSelectEx) return this.playByClickSelect(x, y)
                if(this.isLibertyEx) return this.playByClickLiberty(x, y)
                if(this.playDisabled) return
                // if(!this.coorCheck(x, y)) return
                if(this.disabledCoorCheck(x, y) && !this.userPlayData.length) return
                let status = await this.playByGame(x, y, opt)
                if(!status) return
                this.stoneLabelClear()
                this.userPlayData.push(
                    this._board.board.coor2to1(x, y)
                )
            },
            async playByGame(x, y, opt){
                let remove = this._common.deepCopy(this.removeStone)
                let res = await this._board.playByGame(x, y, opt)
                this.lastRemove = remove
                return res
            },
            removeOutline(x, y){
                // this._board.removeOutline()
            },
            stoneOver(x, y){
                if(this.stoneOverDisabled) return
                if(this.isSelectEx) return this.stoneOverSelect(x, y)
                if(this.playDisabled) return this.removeOutline(x, y)
                this._board.stoneOver(x, y)
                this.boardStyle = {
                    'cursor': 'auto'
                }
            },
            stoneOverSelect(x, y){
                if(!this.sgfRootInfoAL) return
                let data = this.sgfRootInfoAL
                let f = this.coorInALSelect(x, y)
                if(f){
                    this.boardStyle = {
                        'cursor': 'pointer'
                    }
                }else{
                    this.boardStyle = {
                        'cursor': 'auto'
                    }
                }
            },
            coorCheck(x, y){
                let f = true
                if(!this._board.board.isInBoard(x, y)) f = false
                // if(this._board.board.hasStone(x, y)) f = false
                return f
            },
            disabledCoorCheck(x, y){
                let coor = this.disabledCoor
                if(!coor) return false
                return coor.x == x && coor.y == y
            },
            playByClickSelect(x, y){
                if(!this.coorInALSelect(x, y)) return
                let {sgfSelect:{data = []} = {}} = this
                if(!data.length) return
                let f = false
                for(var i in data){
                    let coor = this.selectCoorGet(data[i])
                    if(coor.x == x && coor.y == y){
                        f = data[i]
                        break
                    }
                }
                if(!f) return
                this.answer(f)
                this.playAction(x, y)
                this.sgfALClear()
            },
            selectCoorGet(v){
                let coor = v.val
                if(v.coorIndex){
                    coor = v.coorIndex
                    coor = this._board.board.coor1to2(coor)
                }else{
                    coor = this._board.board.splitGtp(coor)
                }
                return coor
            },
            coorInALSelect(x, y){
                if(!this.sgfRootInfoAL) return false
                let data = this.sgfRootInfoAL
                let f = false
                for(var i in data){
                    if(data[i].x == x && data[i].y == y){
                        f = true
                        break
                    }
                }
                return f
            },

            async sgfParse(sgf){
                this.pageLoading = true
                await this._common.nextTick.call(this)
                sgf = sgf || this.sgf
                await this.dataInit()
                this.clearBoard()
                let res = await this._board.parseSgfWithBranch(sgf)
                if(!res){
                    this.pageLoading = false
                    return
                }
                this.sgfBranch = this._common.deepCopy(res)
                await this._common.nextTick.call(this)
                let data = await this.sgfLoadActionInitPro(this._common.deepCopy(res))
                data = data || {}
                this.sgfData = data.data || []
                this.sgfDataCopy = this._common.deepCopy(data.data)
                await this.sgfBranchBoardInit()
                this.pageLoading = false
                
            },
            async sgfBranchBoardInit(){
                let firstColor = null
                let {lt: coor, nc} = this.sgfRootInfo
                if(nc !== undefined && !isNaN(Number(nc))) firstColor = nc == 1 ? 1 : -1
                else if(coor){
                    let x = this._board.board.StoN(coor[0].toLowerCase())
                    let y = this._board.board.StoN(coor[1].toLowerCase())
                    firstColor = this.getFirstColorByCoor(x, y)
                    this._board.board.setStoneLabel({x: x, y: y, noCheck: true, c: -firstColor})
                    this.lastStone = {x: x, y: y}
                }
                let {sq = [], tr = []} = this.sgfRootInfo
                let {labelList = []} = this
                if(labelList.length){
                    let coors = labelList
                    let stone = this._board.board.stone
                    for(var i in coors){
                        let move = coors[i].coor
                        let type = coors[i].type
                        let x = this._board.board.StoN(move[0].toLowerCase())
                        let y = this._board.board.StoN(move[1].toLowerCase())
                        let c = stone[this._board.board.coor2to1(x, y)]
                        if(c == 1 || c == -1) c = -c
                        else c = 1
                        this._board.board.board.addObject({x: x, y: y, type: 'CLS', label: type, c: c})
                    }
                }
                await this._common.nextTick.call(this)
                if(this.sgfRootInfoAL){
                    this.sgfALSet()
                }
                if(firstColor === null){
                    firstColor = this.getFirstColor()
                }
                if(this.now != firstColor) this.passAction()
                this.userColor = firstColor
            },
            getFirstColor(){
                let data = this.sgfPlayData || []
                let c = 1
                let nodeKey = this.nodeKey
                let sgfData = this.sgfData
                if(sgfData[nodeKey] && sgfData[nodeKey].length){
                    let tem = sgfData[nodeKey][0]
                    if(tem.W !== undefined) c = -1
                    else c = 1
                }else{
                    if(data && data[data.length - 1]) c = -data[data.length - 1].c
                }
                return c
            },
            sgfALSet(){
                if(!this.sgfRootInfoAL) return
                let data = this._common.deepCopy(this.sgfRootInfoAL)
                let stone = this._board.board.stone
                for(var i in data){
                    let c = stone[data[i].coorIndex] || 0
                    data[i].type = 'AL'
                    data[i].c = c
                }
                this._board.board.board.addObject(data)
            },
            getFirstColorByCoor(x, y){
                let data = this.sgfPlayData || []
                let c = 1
                if(!data || !data.length) return c
                for(var i = data.length - 1; i >= 0; i--){
                    if(data[i].x == x && data[i].y == y){
                        c = -data[i].c
                        break
                    }
                }
                return c
            },
            sgfLoadActionInitPro(res){
                return new Promise(success => {
                    this.sgfLoadActionInit(res, null, data => {
                        success(data)
                    })
                })
            },
            sgfBranchParse(res){
                if(Object.keys(res).length > 1) return res
                let nodes = res[this.nodeKey]
                if(!nodes || !nodes.length) return res
                return this.sgfBranchParse(nodes[0])
            },
            commentDataParse(data){
                data = data.split('-')
                let rd = {}
                for(var i in data){
                    let tit = data[i].substring(0, data[i].indexOf('{'))
                    tit = tit.toLowerCase()
                    let con = data[i].substring(1 + data[i].indexOf('{'), data[i].lastIndexOf('}'))
                    rd[tit] = con
                }
                if(rd.type && !isNaN(Number(rd.type))) rd.type = Number(rd.type)
                return rd
            },

            sgfLoadActionInit(data, opt, callback){
                if(this.isSelectEx) return this.sgfLoadAction(data, opt, callback)
                opt = opt || {}
                opt.num = opt.num || 0
                let nodeKey = this.nodeKey
                if(!data){
                    if(callback) callback(opt)
                    return
                }
                data = this._common.deepCopy(data)
                let nodes = data[nodeKey] || []
                nodes = this._common.deepCopy(nodes)
                delete data[nodeKey]
                if(!Object.keys(data).length){
                    data = nodes[0] || {}
                    nodes = data[this.nodeKey] || []
                }
                if(data['AB'] || data['AW']){
                    this.sgfPlay(data)
                }
                let firstColor = null
                if(nodes.length){
                    let tem = nodes[0]
                    if(tem['B'] || tem['AB']) firstColor = 1
                    if(tem['W'] || tem['AW']) firstColor = -1
                }
                callback({firstColor: firstColor, data: data})
            },
            sgfLoadAction(data, opt, callback){
                opt = opt || {}
                opt.num = opt.num || 0
                let nodeKey = this.nodeKey
                if(!data){
                    if(callback) callback(opt)
                    return
                }
                let nodes = data[nodeKey] || []
                if(!opt.nonePlay){
                    this.sgfPlay(data)
                    opt.num++
                }
                
                if(nodes.length > 1 && opt.firstColor === undefined){
                    let d = nodes[0], firstColor
                    opt.nonePlay = true
                    if(nodes.length > 1){
                        if(nodes[0].B !== undefined || nodes[0].AB !== undefined){
                            firstColor = 1
                        }else if(nodes[0].W !== undefined || nodes[0].AW !== undefined){
                            firstColor = -1
                        }
                    }
                    if(firstColor === undefined) firstColor = 1
                    opt.firstColor = firstColor
                }
                opt.step = opt.step || []
                if(nodes.length > 1){
                    opt.data = data
                    if(callback) callback(opt)
                    return
                }
                this.sgfLoadAction(nodes[0], opt, callback)
            },
            sgfPlay(data){
                let nodeKey = this.nodeKey
                let playData = []
                data = data || {}
                for(var i in data){
                    let c, x, y
                    if(i == nodeKey) continue
                    if(i == 'B' || i == 'W'){
                        c = i == 'B' ? 1 : -1
                        x = this._board.board.StoN(data[i][0])
                        y = this._board.board.StoN(data[i][1])
                        if(c != this.playNow) this.passAction()
                        this.playAction(x, y)
                        playData.push({
                            x: x,
                            y: y,
                            c: c
                        })
                    }else if(i == 'AB' || i == 'AW'){
                        let coors = this._board.setAWandAB(i, data[i])
                        playData = playData.concat(coors)
                    }
                }
                this.sgfPlayData = playData
            },
            pass(){
                if(this.playDisabled) return
                this.passAction()
            },
            passAction(){
                this._board.pass()
            },
            playAction(x, y, opt){
                this._board.playAction(x, y, opt)
                if(this.lastStone){
                    this._board.board.removeStoneLabelAction({x: this.lastStone.x, y: this.lastStone.y})
                }
            },
            answer(val){
                if(this.userAnswer) return
                if(!val) return
                this.hintResultOff()
                this.userAnswer = val.item
            },
            hintResultOff(){
                this.hintResultData.active = false
                this.selectAnimation = null
                this.extIntroOff()
                if(this._board) this._board.propOff()
            },
            extIntroOff(){
                this.exercisesVideo = null
            },
            sgfALClear(){
                if(!this.sgfRootInfoAL) return
                let data = this._common.deepCopy(this.sgfRootInfoAL)
                for(var i in data){
                    data[i].type = 'AL'
                }
                this._board.board.board.removeObject(data)
            },
            stoneLabelClear(){
                if(this.sgfRootInfo.sq){
                    let coor = this.sgfRootInfo.sq
                    coor = coor.split(',')
                    let stone = this._board.board.stone
                    for(var i in coor){
                        let x = this._board.board.StoN(coor[i][0].toLowerCase())
                        let y = this._board.board.StoN(coor[i][1].toLowerCase())
                        let c = stone[this._board.board.coor2to1(x, y)]
                        if(c == 1 || c == -1) c = -c
                        else c = 1
                        this._board.board.board.removeObject({x: x, y: y, type: 'CLS'})
                    }
                } 
            },
            selectByBtn(v){
                if(!this.sgfRootInfoAL) return this.answer(v)
                let coor = this.selectCoorGet(v)
                this.playByClickSelect(coor.x, coor.y)
            },
            coorScoreParse(data){
                if(!data || !this._common.isString(data)) return data
                let rd = data
                try{
                    data = data.split('-')
                }catch(e){ data = [] }
                if(!this._common.isArray(data)) return data
                for(var i in data){
                    if(data[i] == 'T' || data[i] == 'F' || data[i] == 'O'){
                        rd = data[i]
                    }
                }
                return rd
            },
            getDataCoor(data){
                data = data || {}
                let key
                if(data.B !== undefined) key = 'B'
                if(data.W !== undefined) key = 'W'
                if(data.AB !== undefined) key = 'AB'
                if(data.AW !== undefined) key = 'AW'
                if(!key) return null
                let tem = data[key]
                if(this._common.isArray(tem)) tem = tem[0]
                let x = this._board.board.StoN(tem[0])
                let y = this._board.board.StoN(tem[1])
                return this._board.board.coor2to1(x, y)
            },
            getPathForTrue(data, path){
                data = data || this.sgfData
                if(!data) return false
                let nodes = data[this.nodeKey] || []
                let coor = this.getDataCoor(data)
                if(!path){
                    path = path || []
                }else{
                    path.push(coor)
                }
                let dataC = this.coorScoreParse(data['C'])
                if(dataC == 'F' || dataC == 'T'){
                    let reData = dataC == 'F' ? false : path
                    return reData
                }
                if(nodes && nodes.length){
                    let pathCopy = this._common.deepCopy(path)
                    let f = false
                    for(var i in nodes){
                        let coors = this.getPathForTrue(nodes[i], [])
                        if(coors !== false && coors !== undefined){
                            path = path.concat(coors)
                            f = true
                            break
                        }
                    }
                    return f ? path : false
                }
                return false
            },
            gameOverRequest(){
                let actual = null, correct = null
                if(this.isPlayEx){
                    actual = this.userPlayData.join(',')
                    if(this.gameResult) correct = actual
                    else{
                        correct = this.getPathForTrue(this.sgfDataCopy)
                        if(correct) correct = correct.join(',')
                    }
                }
                else if(this.isSelectEx){
                    actual = this.userAnswer
                    correct = this.gameResult ? this.userAnswer : this.selectTrueAnswer.item
                }else if(this.isLibertyEx){
                    actual = Object.keys(this.libertyCoor)
                    actual = actual.join(',')
                    if(this.gameResult) correct = actual
                    else {
                        correct = Object.keys(this.libertyCurrectData)
                        correct = correct.join(',')
                    }
                }
                // let exercisesId = this.questionNow.id
                // if(actual === undefined || actual === null || correct === undefined || correct === null) return
                if(!actual || !correct){
                    return
                }

                // this.resultMaskOpen()
                let {gameResult: result, isSelectEx} = this
                this.$emit('gameover', {
                    actual,
                    correct,
                    isSelectEx
                })
            },
            async resultMaskOpen(){
                if(this.pageLoading) return
                let type = ''
                if(this.gameResult) type = 'right'
                else type = 'wrong'
                let time = this.getVoiceTimeout()
                await this._common.settimeout(time)
                this._voiceHand.exercisePlay(type)
                return this.$refs['resultMask'].open()
                
            },
            getVoiceTimeout(){
                let time = 300
                let {lastRemove, removeStone} = this
                if(removeStone.b > lastRemove.b || removeStone.w > lastRemove.w) time = 500
                return time
            },
            resultMaskOff(){
                return this.$refs['resultMask'].close()
            },
            countDownFinish(){
                this.nextEx()
            },
            nextEx(){
                if(this.nextExAction) return
                this.nextExAction = true
                this.$emit('nextExercise')
            },
            confirmPlay(){
                if(!this._board) return
                if(this.isLibertyEx) return this.libertyConfirmPlay()
                this._board.confirmPlay()
            },
            positionChange(){
                if(this.isComputerPlay) this.AIplay()
                this.hintResultOff()
                let a = {"type": "capture", "icon": "img/game/g_2.png", "boardSize": 13, "captureNum": 5, "moveNum": 60,"label":"13路吃5子","intro":"吃5子获胜，60手和棋", "AILevel": "-400", "komi": "5", "rule": "capture"}
            },
            getDelayTime(){
                let start = 1, end = 3
                let num = this._common.randomNumber(start, end)
                return num * 1000
            },
            async AIplay(){
                // let delay = this.AIplayDelayTime
                let delay = this.getDelayTime()
                let coorData = this.getAIpLayCoor() || {}
                if(!coorData || coorData.coor === undefined || coorData.coor === null){
                    delay = 0
                }
                await this._common.settimeout(delay)
                if(coorData && coorData.coor !== undefined && coorData.coor !== null){
                    let coor = this._board.board.coor1to2(coorData.coor)
                    this.playAction(coor.x, coor.y, {AIPlay: true})
                    this.userPlayData.push(
                        this._board.board.coor2to1(coor.x, coor.y)
                    )
                }
                this.sgfData = coorData.sgfData
                this.playedSgfData.push(coorData.sgfDataNext)
                if(coorData.userScore) this.userScore = coorData.userScore
                
            },
            getAIpLayCoor(){
                if(!this.sgfData) return
                let data = this.checkPlayCoor()
                return data
            },
            checkPlayCoor(data, coor){
                data = data || this.sgfData
                let nodes = data[this.nodeKey]
                coor = coor || this._board.board.coor2to1(this.stepMoves[this.stepMoves.length - 1].x, this.stepMoves[this.stepMoves.length - 1].y)
                let nodeCoor = null, sgfData, userScore = null
                let elseData = this.getElseDataCoor(data) || {}, elseSgfData = null, elseUserScore = null
                let elseCoor = elseData.coor
                let findCoor = false
                let sgfDataNext = this._common.deepCopy(data)
                for(var i in nodes){
                    let key
                    if(nodes[i].B !== undefined) key = 'B'
                    if(nodes[i].W !== undefined) key = 'W'
                    if(nodes[i].AB !== undefined) key = 'AB'
                    if(nodes[i].AW !== undefined) key = 'AW'
                    if(!key) continue
                    let tem = nodes[i][key]
                    if(this._common.isArray(tem)) tem = tem[0]
                    let x = this._board.board.StoN(tem[0])
                    let y = this._board.board.StoN(tem[1])
                    tem = this._board.board.coor2to1(x, y)
                    if(tem == coor){
                        findCoor = true
                        sgfData = this._common.deepCopy(nodes[i])
                        userScore = this._common.deepCopy(nodes[i])
                        delete userScore[this.nodeKey]
                        let nextNode = sgfData[this.nodeKey]
                        if(this._common.isArray(nextNode)) nextNode = nextNode[0]
                        sgfData = nextNode
                        nodeCoor = this.getDataCoor(nextNode)
                        break
                    }
                }
                if(!findCoor){
                    let color = this.userColor == 1 ? 'B' : 'W'
                    userScore = {}
                    userScore[color] = coor
                    userScore['C'] = elseData.score
                    nodeCoor = elseData.coor || null
                }
                return {coor: nodeCoor, sgfData: sgfData, userScore: userScore, sgfDataNext: sgfDataNext}
            },
            getElseDataCoor(data){
                data = data || {}
                if(data.C === undefined) return null
                let comment = data.C || ''
                let elData = this.commentDataParse(data['C'])
                if(!elData) return null
                elData = elData['el'] || ''
                elData = elData.split(',')
                let coor = (elData[0]).toLowerCase()
                let score = elData[1]
                let x = this._board.board.StoN(coor[0])
                let y = this._board.board.StoN(coor[1])
                return {coor: this._board.board.coor2to1(x, y), score: score}
            },
            async answerParseOn(type){
                if(!Object.keys(this.answerParseData).length && !this.isSelectEx){
                    let userPlayData = this._common.deepCopy(this.userPlayData)
                    let userSelected = this._common.deepCopy(this.userSelected)
                    let selectTrueAnswer = this._common.deepCopy(this.selectTrueAnswer)
                    let sgfData = this._common.deepCopy(this.sgfData)
                    let libertyCoor = this._common.deepCopy(this.libertyCoor)
                    this.answerParseData = {userPlayData, sgfData, selectTrueAnswer, userSelected, libertyCoor}
                    await this.sgfParse()
                }
                if(this.isSelectEx) return this.selectAnswerParse()
                return type == 0 ? this.correctAnswerParse() : this.ownAnswerParse()
            },
            selectAnswerParse(){
            },
            correctAnswerParse(){
                if(this.isLibertyEx) return this.correctAnswerParseLiberty()
                let actual = this.userPlayData, correct
                if(this.gameResult) correct = actual
                else{
                    correct = this.getPathForTrue(this.sgfDataCopy) || []
                }
                this.clearUserPlayData()
                this.setCorrectAnswer()
            },
            clearUserPlayData(data){
                this._board.branchOff()
            },
            setCorrectAnswer(data){
                data = data || this.getPathForTrue(this.sgfDataCopy)
                this._board.branchOn(data)
            },
            ownAnswerParse(){
                if(this.isLibertyEx) return this.ownAnswerParseLiberty()
                let clearData = this.getPathForTrue(this.sgfDataCopy)
                this.clearUserPlayData(clearData)
                let {
                    answerParseData: {userPlayData: data = []} = {}
                } = this
                this.setCorrectAnswer(data)
            },
            answerParseOff(){
                this.answerParseData = {}
            },
            async playByClickLiberty(x, y){
                if(!this.coorCheck(x, y)) return
                let status = await this.playByGameLiberty(x, y)
                if(!status) return
                // this.stoneLabelClear()
                this.userPlayData.push(
                    this._board.board.coor2to1(x, y)
                )
            },
            async playByGameLiberty(x, y){
                let type = this.lb
                let res = await this._board.playByGameLiberty(x, y, {type})
                return res
                
            },
            libertyConfirmPlay(){
                let list = this._common.deepCopy(this.libertyCoor)
                this.libertyConfirm = true
            },
            correctAnswerParseLiberty(){
                let {libertyCoor} = this.answerParseData || {}
                let data = Object.keys(libertyCoor)
                this.clearLiberty(data)
                data = Object.keys(this.libertyCurrectData)
                this._board.board.libertyRefresh(data)
                // actual = Object.keys(this.libertyCoor)
                //     actual = actual.join(',')
                //     if(this.gameResult) correct = actual
                //     else {
                //         correct = Object.keys(this.libertyCurrectData)
                //         correct = correct.join(',')
                //     }
            },
            ownAnswerParseLiberty(){
                let data = Object.keys(this.libertyCurrectData)
                this.clearLiberty(data)
                let {libertyCoor} = this.answerParseData || {}
                data = Object.keys(libertyCoor)
                this._board.board.libertyRefresh(data)
            },
            clearLiberty(data){
                this._board.board.clearLiberty(data)
            }   ,
            clearAllLiberty(){
                this.libertyCoor = {}
                this._board.clearAllLiberty()
            },
            stopCountdown(){
                if(!this.$refs['resultMask']) return
                this.$refs['resultMask'].close()
            }
        },
        created() {

        },
        components: {
            board,
            resultMask
        },
        computed: {
            disabledMoveConfirm(){
                return this.isSelectEx || this.isLibertyEx
            },
            lb(){
                let {sgfRootInfo: {lb = 0}} = this
                return lb
            },
            labelList(){
                let info = this.sgfRootInfo || {}
                let sq = info.sq || ''
                let tr = info.tr || ''
                sq = sq.split(',')
                tr = tr.split(',')
                let rd = []
                for(let i in sq){
                    if(!sq[i]) continue
                    rd.push({
                        type: 'sq',
                        coor: sq[i]
                    })
                }

                for(let i in tr){
                    if(!tr[i]) continue
                    rd.push({
                        type: 'tr',
                        coor: tr[i]
                    })
                }
                return rd
            },
            confirmText(){
                if(this.isPlayEx) return this.TEXT.components.t16
                return this.TEXT.components.t17
            },
            selectParseList(){
                let {questionSelect: list, currentAnswer, userAnswer} = this
                let rd = []
                for(let i in list){
                    let {item} = list[i]
                    if(item == currentAnswer || item == userAnswer) rd.push(list[i])
                }
                return rd
            },
            isComputerPlay(){
                if(this.userColor !== null && this.userColor != this.playNow) return true
                return false
            },
            confirmDisabled(){
                if(this.isLibertyEx) return this.libertyConfirmDisabled
                let {gamePlayCoor} = this
                return !gamePlayCoor || !Object.keys(gamePlayCoor).length
            },
            libertyConfirmDisabled(){
                let {libertyCoor} = this
                return !libertyCoor || !Object.keys(libertyCoor).length
            },
            boardSize(){
                let {sgfRootData: root = {}} = this
                let size = 19
                for(let i in root){
                    let k = i.toLowerCase()
                    if(k == 'sz'){
                        size = root[i]
                    }
                }
                size = Number(size)
                if(isNaN(size)) size = 19
                return size
            },
            userScoreCul(){
                return this.userScore || {}
            },
            gameResult(){
                if(this.isSelectEx) return this.gameResultSelect
                if(this.isLibertyEx) return this.gameResultLiberty
                let {userScoreCul: score} = this
                let scoreData = this.coorScoreParse(score['C'])
                return scoreData == 'T'
            },
            gameResultLiberty(){
                if(!this.gameOverCul) return false
                let {libertyCurrectData: data, libertyCoor: moves} = this
                if(Object.keys(data).length != Object.keys(moves).length) return false
                let f = true
                for(let i in moves){
                    if(!data[i]){
                        f = false
                        break
                    }
                }
                return f
            },
            libertyCurrectData(){
                if(!this.boardState) return {}
                let {ca = ''} = this.sgfRootInfo
                try{
                    ca = ca.split(',')
                }catch(e){ ca = [] }
                let rd = {}
                for(let i in ca){
                    let move = ca[i]
                    let x = this._board.board.StoN(move[0].toLowerCase())
                    let y = this._board.board.StoN(move[1].toLowerCase())
                    let coor = this._board.board.coor2to1(x, y)
                    rd[coor] = {x, y}
                }
                return rd
            },
            gameResultSelect(){
                let userAnswer = this.userAnswer
                if(!this.gameOverCul) return false
                return userAnswer === this.selectTrueAnswer.item
            },
            stoneOverDisabled(){
                if(this.gameOverCul || !this.confirmDisabled) return true
                return false
            },
            disabledCoor(){
                let info = this.sgfRootInfo || {}
                let coor = info['jr']
                if(!coor) return null
                let x = coor[0].toLowerCase(), y = coor[1].toLowerCase()
                x = this._board.board.StoN(x)
                y = this._board.board.StoN(y)
                return {x, y}
            },
            sgfSelect(){
                if(!this.sgfRootInfo || !Object.keys(this.sgfRootInfo).length) return {}
                let data = this.sgfRootInfo.s
                let al = this.sgfRootInfoAL || null
                if(!data) return {}
                data = data.split(',')
                for(var i in data){
                    data[i] = data[i].split(':')
                    let tem = {
                        item: data[i][0],
                        val: data[i][1],
                    }
                    if(al && al[i]){
                        tem.coorIndex = al[i].coorIndex
                    }
                    data[i] = tem
                }
                let opt = {ca: this.sgfRootInfo.ca, data: data}
                return opt
            },
            isSelectEx(){
                return this.gameType == 1
            },
            isPlayEx(){
                return this.gameType == 0
            },
            isLibertyEx(){
                return this.gameType == 2
            },
            currentAnswer(){
                let {ca} = this.sgfRootInfo
                return ca
            },
            selectTrueAnswer(){
                let {questionSelect: data, sgfRootInfo: info} = this
                if(!info.ca) return {}
                let rd = {}
                for(var i in data){
                    if(data[i].item == info.ca){
                        rd = data[i]
                        break
                    }
                }
                return rd
            },
            userSelected(){
                if(!this.isSelectEx) return null
                let {questionSelect: data, userAnswer} = this
                let rd = null
                for(var i in data){
                    if(data[i].item == userAnswer){
                        rd = data[i]
                        break
                    }
                }
                return rd
            },
            playDisabled(){
                if(this.gameOverCul) return true
                if(this.userColor === null || this.userColor != this.playNow) return true
                return false
            },
            playNow(){
                return this.now
            },
            gameOverCul(){
                if(this.isSelectEx) return this.gameOverCulSelect
                if(this.isLibertyEx) return this.gameOverCulLiberty
                if(!this.sgfData || !Object.keys(this.sgfData).length) return true
                let nodes = this.sgfData[this.nodeKey]
                if(!nodes || !nodes.length) return true
                return false
            },
            gameOverCulLiberty(){
                let {libertyConfirm} = this
                if(!libertyConfirm) return false
                return true
            },
            gameOverCulSelect(){
                if(this.userAnswer === null) return false
                return true
            },
            sgfRootInfoAL(){
                if(!this.sgfRootInfo.al) return
                let data = this.sgfRootInfo.al
                try{
                    data = data.split(',')
                }catch(e){ data = [] }
                if(!this._common.isArray(data)) return null
                let rd = []
                for(var i in data){
                    let tem = data[i].split(':')
                    let coor = tem[1]
                    let txt = tem[0]
                    let x = this._board.board.StoN(coor[0].toLowerCase())
                    let y = this._board.board.StoN(coor[1].toLowerCase())
                    let coorIndex = this._board.board.coor2to1(x, y)
                    rd.push({
                        x: x,
                        y: y,
                        txt: txt,
                        coorIndex: coorIndex
                    })
                }
                return rd
            },

            questionTitle(){
                return this.sgfRootInfo.q || null
            },
            questionSelect(){
                let {sgfSelect:{data = []} = {}} = this
                return data
            },
            sgfRootInfo(){
                let {sgfRootData: root} = this
                if(!root || !root['C']) return {}
                let data = this.commentDataParse(root['C'])
                return data 
            },
            gameType(){
                if(!this.sgfRootInfo || !Object.keys(this.sgfRootInfo).length) return null
                return Number(this.sgfRootInfo.type)
            },
            sgfRootData(){
                let {sgfBranch: data = {}} = this
                if(this.sgfBranchNull) return
                data = this.sgfBranchParse(this._common.deepCopy(data))
                delete data[this.nodeKey]
                return data
            },
            sgfBranchNull(){
                let {sgfBranch: data = {}} = this
                return Object.keys(data).length <= 0
            },
            sgfBranchState(){
                let {boardState, sgf} = this
                if(!boardState || !sgf) return false
                return true
                // return {boardState, sgf}
            },
            boardMinNum(){
                return 0
            },
            titleText(){
                return ''
            },
        },
        watch: {
            // libertyCoor: {
            //     handler(val){
            //     },
            //     deep: true,
            //     immediate: true
            // },
            position: {
                handler(newVal, oldVal){
                    this.positionChange({newVal: newVal, oldVal: oldVal, type: 'watch'})
                },
                deep: true,
                immediate: true
            },
            sgfBranchState: {
                handler(val){
                    if(!val) return
                    this.answerParseOff()
                    this.sgfParse()
                    
                },
                deep: true,
                immediate: true
            },
            gameOverCul: {
                handler(val, oldval){
                    if(val) this.gameOverRequest()
                },
                deep: true,
                immediate: true
            },
            gameResult: {
                handler(val, oldval){
                    this.$emit('update', {
                        gameResult: val
                    })
                },
                deep: true,
                immediate: true
            },
        },
        mounted() {},
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {
            this._voiceHand.pauseAll()
        }, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>
<style lang='less' >
    .com-exercise{
        position: relative;
        
        .com-board{
            // ._le-board-box{
            //     box-shadow: 2px 2px 5px 2px #7d7abe;
            // }
        }
        .ex-bcg-box{
            position: fixed;
            top: 0px;
            left: 0px;
            width: 100vw;
            height: 100vh;
            .stone-item{
                position: absolute;
            }
            .black{
                width: 94px;
                height: 51px;
                bottom: 0px;
                left: 0px;
                .background-img-max(url('@{assetsUrlV2_0}/exercise/c1.png'));
            }
            .white{
                width: 93px;
                height: 58px;
                top: 0px;
                right: 0px;
                .background-img-max(url('@{assetsUrlV2_0}/exercise/c2.png'));
            }
        }
        .exercise-body{
            display: flex;
            align-items: center;
            position: relative;
            // width: calc(@board-size + @board-options-margin + @game-options-size);
            // height: @board-size;
            .ex-shadow{
                position: absolute;
                width: 588px;
                height: 353px;
                top: -7px;
                left: -10px;
                .background-img-max(url('@{assetsUrlV2_0}/exercise/c4.png'));
            }
            .board{
                .board-body{
                    position: relative;
                    width: 100%;
                    height: 100%;
                }
            }
            .options{
                position: relative;
                @title-height: 22px;
                @foot-height: 72px;
                padding: 35px 12px 28px;
                .options-title{
                    position: absolute;
                    top: 6px;
                    left: 0px;
                    width: 100%;
                    text-align: center;
                    color: #465D71;
                }
                .options-body{
                    height: 100%;
                    .title{
                        height: @title-height;
                        // border-bottom: 1px solid #F3F3F3;
                        display: flex;
                        align-items: flex-start;
                        position: relative;
                        background: url('@{assetsUrlV2_0}/exercise/c7.png') no-repeat;
                        background-size: 100% 2px;
                        background-position: left bottom;
                        .title-text{
                            width: calc(100% - 6px);
                            padding-left: 8px;
                            display: flex;
                            color: #625555;
                            height: 15px;
                            span[class^='text']{
                                text-align: left;
                                height: 15px;
                                line-height: 15px;
                            }
                        }
                        .title-icon{
                            width: 6px;
                            height: 15px;
                            // background: linear-gradient(360deg, #FFC1C7 0%, #FF5865 72%, #FF3741 100%);
                            // border-radius: 3px;
                            .background-img-max(url('@{assetsUrlV2_0}/exercise/c8.png'));

                        }
                    }
                    .opt-content{
                        width: 100%;
                        padding: 12px 0px;
                        height: calc(100% - @title-height - @foot-height);
                        color: #656060;
                        p[class^='text']{
                            text-align: left;
                            line-height: 20px;
                        }
                    }
                    .opt-content.par-content{
                        height: calc(100% - @title-height - @foot-height - 10px);
                    }
                    .opt-foot.slot-box{
                        position: absolute;
                        bottom: 20px;
                        left: 0px;
                        width: 100%;
                        padding: 0px 12px;
                    }
                    .opt-foot{
                        
                        .foot-select-box{
                            display: flex;
                            height: @foot-height;
                            align-items: flex-end;
                        }
                        .foot-select-box.par-btn{
                            height: calc(@foot-height - 30px);
                        }
                        .foot-select-box.sele{
                            justify-content: space-between;
                            align-content: space-between;
                            flex-wrap: wrap;
                        }
                        .foot-item.right{
                            .com-dia-button{
                                // background: #03E16E;
                                .background-img-max(url('@{assetsUrlV2_0}/exercise/c11.png'));
                            }
                        }
                        .foot-item.wrong{
                            .com-dia-button{
                                // background: #FFAC40;
                                .background-img-max(url('@{assetsUrlV2_0}/exercise/c10.png'));
                            }
                        }
                        .foot-item.ca-select{
                            .com-dia-button{
                                color: #fff;
                                border-color: #fff;
                            }
                        }
                        // .foot-item.right::before{
                        //     background: url('@{assetsUrl}/img/subject/s1.png');
                        //     background-size: 100% 100%;
                        // }
                        // .foot-item.wrong::before{
                        //     background: url('@{assetsUrl}/img/subject/s2.png');
                        //     background-size: 100% 100%;
                        // }
                        // .foot-item::before{
                        //     content: '';
                        //     position: absolute;
                        //     width: 18px;
                        //     height: 18px;
                        //     right: -9px;
                        //     top: 0px;
                        //     bottom: 0px;
                        //     margin: auto 0px;
                        // }
                        
                        .foot-item::before, .foot-item::after{
                            content: '';
                            position: absolute;
                            left: 84px;
                            width: 16px;
                            height: 16px;
                            top: 0px;
                            bottom: 0px;
                            margin: auto 0px;
                        }
                        .foot-item.right::before, .foot-item.wrong::before{
                            .background-img-max(url('@{assetsUrlV2_0}/exercise/c14.png'));
                        }
                        .foot-item.right::after{
                            width: 15px;
                            height: 11px;
                            margin-left: 2px;
                            .background-img-max(url('@{assetsUrlV2_0}/exercise/c13.png'));
                        }
                        .foot-item.wrong::after{
                            width: 12px;
                            height: 12px;
                            margin-left: 3px;
                            .background-img-max(url('@{assetsUrlV2_0}/exercise/c12.png'));
                        }
                        .foot-item{
                            position: relative;
                            .com-dia-button{
                                width: 93px;
                                height: 30px;
                                border-radius: 0px;
                                border: 0px;
                                color: #625555;
                                .background-img-max(url('@{assetsUrlV2_0}/exercise/c9.png'));
                                .text-normal-a{
                                    font-size: @normal-font-size;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
</style>